<template>
  <div>
    <app-header>オフラインでのライセンス認証</app-header>   
    <form-input-offline></form-input-offline>
  </div>
</template>

<script>
  import FormInputOffline from '@/components/FormInputOffline.vue'
  import AppHeader from '@/components/AppHeader' 

  export default {
    name: 'form_for_offline',
    components: {
      FormInputOffline,
      AppHeader
    },
    computed: {
    },
    methods:{
    }
}
</script>

<style scoped>
h1, h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
</style>
