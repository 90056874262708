<template>
  <div>
    <b-card title="オフライン認証用コードの入力">
      <b-form @submit="onSubmit">
        <b-container fluid>
            <b-row>
                <b-col sm="3">
                    <label>オフライン認証用コード：</label>
                </b-col>
                <b-col sm="9">
                    <b-form-input id="mail" v-model="info.offline_code" type="text" required placeholder="" />
                </b-col>
            </b-row>
        </b-container>
        <div style="margin-top:20px">
            <b-button type="submit" variant="primary">ライセンス認証を実行する</b-button>
        </div>
        <div v-if="api_response.failed" style="margin-top:10px;">
            <b-alert show variant="warning">{{ api_response.failed_message }}</b-alert>
        </div>
      </b-form>
    </b-card>   

    <hr>

    <b-card title="パスコード">
        <div>
            以下に表示されたパスコードをコピーし、ライセンス認証画面に貼り付けてください。
        </div>
        <b-container fluid>
            <b-row>
                <b-col sm="3">
                    <label>パスコード：</label>
                </b-col>
                <b-col sm="9">
                    <b-form-input id="text" v-model="api_response.pass_code_encoded" type="text" />
                </b-col>
            </b-row>
        </b-container>
    </b-card>
  </div>
</template>

<script>
  var axios = require('axios')
  export default {
    components:{
    },
    data () {
        return{
            info:{
                offline_code : ''
            },
            api_response:{
                failed: false, //通信失敗？
                failed_message: String,
                pass_code_encoded: '' //BASE64でエンコードした値
            }
        }
    },
    computed:{
    },
    props: {
    },
    methods:{
    //ライセンス情報の取得
        onSubmit : function(evt){
            evt.preventDefault();
            var vm = this
            var endpoint = process.env.VUE_APP_LICENSE_API_BASE + '/v4/licensekey/activate_offline'
            var decode = new Buffer(vm.info.offline_code,'base64');
            var x = decode.toString('utf8');
            try{
                var json = JSON.parse(x);
                axios.post(endpoint, json)
                    .then(function(res){
                        //APIのレスポンスを取得
                        var r = res.data
                        console.log(r)
                        if (r.status == 'OK'){
                            vm.api_response.failed = false
                            vm.api_response.failed_message = r.error_message
                            
                            var x = r.pass_code
                            var buffer = new Buffer(x)

                            var encoded_code = buffer.toString('base64')
                            var mod = encoded_code.length % 4
                            if(mod > 0)
                            {
                                encoded_code += "=".repeat(4 - mod)
                            }
                            vm.api_response.pass_code_encoded = encoded_code
                        }
                        //オフライン認証用コードに何か問題があった場合
                        else
                        {
                            vm.api_response.failed = true
                            vm.api_response.failed_message = r.error_message
                            vm.api_response.pass_code = ""
                        }
                    })
                    //サーバエラーの場合
                    .catch(function(error){
                        vm.api_response.failed = true
                        vm.api_response.failed_message = `ライセンスサーバでエラーが発生しました。しばらく時間をおいてお試しください。(${error})`
                        vm.api_response.pass_code = ""
                    })
            }catch(e){
                vm.api_response.failed = true
                vm.api_response.failed_message = `オフライン認証用コードが正しくありません。`
                vm.api_response.pass_code = ""
            }
        }
    }
  }
</script>

<style scoped>
.row {
    margin-top:10px;
}
</style>
